/*
 * @Description:
 * @Date: 2021-09-06 09:49:27
 */
import axios from '../utils/axios'
import config from '../config'

export const govLogin = data => {
  // 政府端登录
  return axios.post('shuxi-cb-user/accounts/login', data)
}
export const entLogin = data => {
  // 企业端登录
  return axios.post('shuxi-cb-user/accounts/entLogin', data)
}
export const getLoginUser = () => {
  // 获取当前登录用户信息
  return axios('shuxi-cb-user/accounts/getLoginUser')
}
export const loginEntList = data => {
  // 登录企业列表
  return axios.post(config.httpPrefix + '/ent/loginEntList', data)
}
export const dictsAll = () => {
  // 获取字典项
  return axios('shuxi-cb-dict/dicts/all')
}
export const addressGetTownStreet = regionId => {
  // 获取街道列表
  return axios(config.httpPrefix + `/address/getTownStreet/${regionId}`)
}
export const queryIndustry = data => {
  // 查询行业
  return axios.post(config.httpPrefix + '/industry/queryIndustry', data)
}

export const getWWLogin = data => {
  // 免密登录
  return axios.post('shuxi-cb-user/accounts/wwLogin', data)
}

export const getCode = () => {
  return axios.get(`shuxi-cb-user/accounts/newCaptcha`)
}
