/*
 * @Description:
 * @Date: 2021-03-24 10:47:22
 * @FilePath: \da-tool\src\config\index.js
 */
export default {
  imgDomain: 'https://shuxi-ct.oss-cn-hangzhou.aliyuncs.com/',
  AMapKey: 'caf718f154f3142973228e7d32d38a7a',
  crypto: {
    iv: 'Kb3Xp4XPhyYUgRi3',
    key: 'IB5ZXAcNbofJCgLB'
  },
  httpPrefix: 'shuxi-xc-cb-ent'
}
